import getMerchant from "./utils/getMerchant";
import updateMerchant from "./utils/updateMerchant";
import extractAdvancedConfig from "./utils/extractAdvancedConfig";
import extractNumberOfMonths from "./utils/extractNumberOfMonths";

export const utils = {
  getMerchant,
  updateMerchant,
  extractAdvancedConfig,
  extractNumberOfMonths,
};

export * from "./utils/constants";
export * from "./Preview";
export * from "./Scene";
export * from "./UnknownMerchant";
