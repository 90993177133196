import React from "react";
import { Layout, Page } from "@shopify/polaris";

import { PolarisProvider } from "../../../components";

export const Scene = ({ children }) => (
  <PolarisProvider>
    <Page title="Dashboard" separator>
      <Layout>{children}</Layout>
    </Page>
  </PolarisProvider>
);
