const extractNumberOfMonths = (str) => {
  if (!str) {
    return 0;
  }

  const PROMO_REGEX = /\d{1,2}/gm;
  const matches = PROMO_REGEX.exec(str);

  if (matches.length < 1) {
    return 0;
  }

  return parseInt(matches[0]);
};

export default extractNumberOfMonths;
