import React from "react";
import { Banner, Layout, Link } from "@shopify/polaris";

export const UnknownMerchant = () => (
  <Layout.Section>
    <Banner title="Sorry, we could not identify this store." status="critical">
      <p>
        If you are an accredited merchant with Latitude Financial Services, please{" "}
        <Link url="mailto:applyandbuy.anz@latitudefinancial.com?subject=Shopify Latitude Promotions App Error" external>
          contact us
        </Link>
        .
      </p>
    </Banner>
  </Layout.Section>
);
