import { ALLOWED_ADVANCED_CONFIG_KEYS, LAYOUT_TYPE_CUSTOM } from "./constants";

const extractAdvancedConfig = (value = {}) => {
  // taking copy of the response and altering it just for advanced config only
  let config = {
    ...value,
  };

  if (config?.layout !== LAYOUT_TYPE_CUSTOM) {
    delete config["layout"];
  }

  if (Object.keys(config).length < 1) {
    return "";
  }

  if (
    Object.keys(config).filter((key) =>
      ALLOWED_ADVANCED_CONFIG_KEYS.includes(key)
    ).length < 1
  ) {
    return "";
  }

  return JSON.stringify(config, ALLOWED_ADVANCED_CONFIG_KEYS, 4);
};

export default extractAdvancedConfig;
