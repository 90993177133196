import React, { useState, useEffect, useCallback } from "react";
import querystring from "querystring";
import {
  Card,
  Collapsible,
  FormLayout,
  Frame,
  Layout,
  SkeletonBodyText,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";

import { logger } from "../../../core";

import {
  Preview,
  Scene,
  UnknownMerchant,
  utils,
  LAYOUT_TYPE,
  LAYOUT_TYPE_CUSTOM,
  ALLOWED_ADVANCED_CONFIG_KEYS,
  DEFAULT_MINIMUM_AMOUNT,
} from "../../../layout/embed/shopify";
import extractAdvancedConfig from "../../../layout/embed/shopify/utils/extractAdvancedConfig";
import { hydratePromotionInfo } from "../../../services/checkout";

const { getMerchant, updateMerchant } = utils;

const Dashboard = ({ location }) => {
  const [merchant, setMerchant] = useState({});
  const [advancedConfig, setAdvancedConfig] = useState({});
  const [minAmount, setMinAmount] = useState();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isAdvancedActive, setIsAdvancedActive] = useState(false);

  const handleAdvancedConfigChange = useCallback(
    (newValue) => setAdvancedConfig(newValue),
    []
  );

  const handleMinAmountChange = useCallback(
    (newValue) => setMinAmount(newValue),
    []
  );

  const { merchantId, token } = location?.search
    ? querystring.parse(location.search.substring(1))
    : {};

  useEffect(() => {
    const fetchMerchant = async () => {
      if (!merchantId || !token) {
        setLoading(false);
        return;
      }

      logger.addContext("merchantId", merchantId);

      try {
        const response = await getMerchant(merchantId, token);

        if (!response) {
          setAlert({
            message: "Could not get merchant details.",
            isError: true,
          });
          setLoading(false);
          return;
        }

        setMerchant(hydratePromotionInfo(response));
        setMinAmount(response?.productWidget?.minAmount);
        setAdvancedConfig(extractAdvancedConfig(response?.productWidget));
        setLoading(false);
      } catch (err) {
        logger.error({ err });
        setAlert({
          message: "Unable to parse merchant details.",
          isError: true,
        });
        setLoading(false);
      }
    };

    fetchMerchant();
  }, [merchantId, token]);

  const handleMerchantChange = (value) => {
    setMerchant({
      ...merchant,
      productWidget: { ...merchant.productWidget, ...value },
    });
  };

  const handlePromotionChange = (promoCode) => {
    const promo = merchant?.promotions?.find(({ code }) => code === promoCode);

    setMerchant({
      ...merchant,
      productWidget: {
        ...merchant.productWidget,
        promotionCode: promoCode,
        promotionMonths: String(promo.promotionMonths),
        paymentOption: promo.type,
        minAmount: minAmount,
      },
    });
  };

  const validateAdvancedConfig = () => {
    let parsed = {};

    if (advancedConfig === "" || Object.keys(advancedConfig).length < 1) {
      return {
        parsed,
      };
    }

    try {
      parsed = JSON.parse(advancedConfig);

      if (!parsed?.layout && merchant.productWidget.layout === "custom") {
        merchant.productWidget.layout = "standard";
      }

      if (parsed?.layout !== LAYOUT_TYPE_CUSTOM) {
        delete parsed["layout"];
      }

      const hasInvalidKey =
        Object.keys(parsed).filter(
          (key) => !ALLOWED_ADVANCED_CONFIG_KEYS.includes(key)
        ).length > 0;

      if (hasInvalidKey) {
        return {
          error: "Advanced config contains invalid data.",
        };
      }
    } catch (e) {
      return {
        error: "Please enter a valid JSON for advanced config.",
      };
    }

    return {
      parsed,
    };
  };
  const productWidgetLayout = merchant?.productWidget?.layout;

  const validateWidgetConfig = () => {
    if (!merchant?.productWidget) {
      return {
        error: "Nothing to save.",
      };
    }
    const isStandardOrInversed = [
      LAYOUT_TYPE.STANDARD.value,
      LAYOUT_TYPE.INVERSED.value,
    ].includes(productWidgetLayout);

    if (isStandardOrInversed && !merchant?.productWidget?.promotionMonths) {
      return {
        error: "Promotion months are mandatory.",
      };
    }

    if (
      productWidgetLayout === LAYOUT_TYPE_CUSTOM &&
      merchant?.productWidget?.copyContainer &&
      !merchant?.productWidget?.promotionMonths
    ) {
      return {
        error: "Promotion months are mandatory.",
      };
    }

    return;
  };
  if (productWidgetLayout === "fullwidth") {
    merchant.productWidget.layout = LAYOUT_TYPE.STANDARD.value;
  }

  if (productWidgetLayout === "inline") {
    merchant.productWidget.layout = LAYOUT_TYPE.LOGO.value;
  }

  const saveMerchant = async () => {
    if (saving) {
      return;
    }

    const { parsed: parsedAdvancedConfig, error: advancedConfigError } =
      validateAdvancedConfig() || {};

    if (advancedConfigError) {
      setAlert({
        message: advancedConfigError,
        isError: true,
      });
      return;
    }

    const { error: widgetConfigError } = validateWidgetConfig() || {};

    if (widgetConfigError) {
      setAlert({
        message: widgetConfigError,
        isError: true,
      });
      return;
    }

    setSaving(true);

    const widgetMinimumAmount =
      merchant?.productWidget.paymentOption === "equal"
        ? minAmount
        : DEFAULT_MINIMUM_AMOUNT;

    const updatedMerchant = {
      ...merchant,
      productWidget: {
        layout: merchant?.productWidget.layout,
        paymentOption: merchant?.productWidget.paymentOption,
        promotionCode: merchant?.productWidget.promotionCode,
        promotionMonths: merchant?.productWidget.promotionMonths,
        minAmount: widgetMinimumAmount,
        ...parsedAdvancedConfig,
      },
    };

    try {
      await updateMerchant(token, updatedMerchant);

      setAlert({
        message: "Config updated.",
        isError: false,
      });
    } catch (err) {
      logger.error(err.message);

      setAlert({
        message: "Could not update config. Please try again.",
        isError: true,
      });
    }

    setSaving(false);
  };

  const toastMarkup = alert?.message ? (
    <Toast
      content={alert?.message}
      error={Boolean(alert?.isError)}
      onDismiss={() => setAlert(null)}
    />
  ) : null;

  const hasMerchantLoaded = merchant?.promotions;

  return (
    <Scene>
      {!loading && !hasMerchantLoaded && <UnknownMerchant />}

      {loading && (
        <Layout.Section>
          <SkeletonBodyText />
        </Layout.Section>
      )}

      {hasMerchantLoaded && (
        <>
          <Layout.AnnotatedSection
            title="Product widget"
            description="Customize how messaging looks on product page"
          >
            <Card
              primaryFooterAction={{
                content: saving ? "Saving .." : "Save",
                onAction: saveMerchant,
              }}
            >
              <Preview merchant={merchant} />

              <Card.Section title="Config">
                <FormLayout>
                  <Select
                    id="widgetLayout"
                    label="Layout"
                    labelInline
                    options={Object.values(LAYOUT_TYPE)}
                    onChange={(value) =>
                      handleMerchantChange({ layout: value })
                    }
                    value={merchant?.productWidget?.layout}
                  />

                  <Select
                    label="Default promotion"
                    labelInline
                    options={merchant?.promotions?.map(
                      ({ code, description }) => ({
                        value: code,
                        label: description,
                      })
                    )}
                    onChange={handlePromotionChange}
                    value={merchant?.productWidget?.promotionCode}
                    placeholder="Select Promotion"
                  />
                </FormLayout>
              </Card.Section>

              <Card.Section
                subdued
                title="Advanced config"
                actions={[
                  {
                    content: isAdvancedActive ? "Hide" : "Show",
                    onAction: () => setIsAdvancedActive(!isAdvancedActive),
                  },
                ]}
              >
                <Collapsible
                  open={isAdvancedActive}
                  id="latitude-advanced-settings"
                  transition={{ duration: "150ms", timingFunction: "ease" }}
                >
                  <FormLayout>
                    {merchant?.productWidget?.paymentOption === "equal" && (
                      <TextField
                        value={minAmount}
                        onChange={handleMinAmountChange}
                        label="Minimum amount for widget to show"
                        helpText="The widget won't show for products under this amount. Must be over $250"
                        placeholder={DEFAULT_MINIMUM_AMOUNT}
                        prefix="$"
                        type="number"
                      />
                    )}
                    <TextField
                      value={advancedConfig}
                      onChange={handleAdvancedConfigChange}
                      helpText={
                        <span>
                          Please refer to Integration guide or contact
                          Relationship Manager for further clarification.
                        </span>
                      }
                      multiline={5}
                    />
                  </FormLayout>
                </Collapsible>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </>
      )}
      <Frame>{toastMarkup}</Frame>
    </Scene>
  );
};

export default Dashboard;
