import React from "react";
import { Card } from "@shopify/polaris";

import { Banner } from "../../../../assets/components/product";

export const Preview = ({ merchant }) => (
  <Card.Section subdued title="Preview for a $1000 product">
    <div
      dangerouslySetInnerHTML={{
        __html: Banner({
          layout: merchant?.productWidget?.layout,
          currency: merchant?.currency,
          containerClass: "",
          price: 1000,
          paymentOption: merchant?.productWidget?.paymentOption,
          promotionMonths: merchant?.productWidget?.promotionMonths,
          minAmount: merchant?.productWidget?.minAmount,
          allowPriceBreakdown:
            merchant?.productWidget?.paymentOption === "equal" ? true : false,
        }),
      }}></div>
  </Card.Section>
);
