export const LAYOUT_TYPE = {
  STANDARD: {
    label: "Standard",
    value: "standard",
  },
  LOGO: {
    label: "Logo only",
    value: "logo-only",
  },
  INVERSED: {
    label: "Inversed",
    value: "inversed",
  },
  DISABLED: {
    label: "Disabled",
    value: "",
  },
};

export const PAYMENT_OPTIONS = {
  EQUAL: {
    label: "Equal monthly",
    value: "equal",
  },
  MINIMUM: {
    label: "Minimum Monthly or Flexible",
    value: "minimum",
  },
};

export const LAYOUT_TYPE_CUSTOM = "custom";

export const ALLOWED_ADVANCED_CONFIG_KEYS = [
  "layout",
  "containerId",
  "containerClass",
  "imageContainer",
  "copyContainer",
  "linkContainer",
  "stackedLogo",
];

export const DEFAULT_MINIMUM_AMOUNT = "250";
