import { put, ENDPOINTS } from "../../../../services/checkout";

const updateMerchant = async (token, merchant) => {
  const { merchantID: merchantId, productWidget } = merchant;

  if (!merchantId) {
    throw new Error("updateMerchant(): Invalid request");
  }

  const response = await put({
    endpoint: ENDPOINTS.MERCHANT,
    token,
    query: {
      merchantId,
    },
    body: {
      productWidget,
    },
  });

  return response;
};

export default updateMerchant;
