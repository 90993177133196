import { get, ENDPOINTS } from "../../../../services/checkout";

const getMerchant = async (merchantId, token) => {
  const { productWidget = {}, ...otherParams } = await get({
    endpoint: ENDPOINTS.MERCHANT,
    token,
    params: {
      merchantId
    }
  });

  return {
    productWidget: productWidget || {},
    ...otherParams
  };
};

export default getMerchant;
